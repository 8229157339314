@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.home {
  position: relative;
}

.navbar {
  width: 100%;
  height: 15vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 10;
}

.navbar .head1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  height: 100%;
  background-color: transparent;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar .head1 h1 {
  font-size: 20px;
  color: #dc143c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  letter-spacing: 1ch;
  font-family: 'Montserrat Alternates', sans-serif;
}

.navbar .head1 h1 span {
  font-size: 43px;
  color: #003893;
  font-weight: bolder;
}

.navbar .head2 {
  width: 70%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  padding: 0 7% 0 0;
}

.navbar .head2 .links {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
}

.navbar .head2 .links a {
  text-decoration: none;
  color: #dc143c;
  margin: 0 2%;
  font-weight: bolder;
}

.navbarChange {
  width: 100%;
  height: 15vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 10;
}

.navbarChange .head1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  height: 100%;
  background-color: transparent;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbarChange .head1 h1 {
  font-size: 20px;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  letter-spacing: 1ch;
  font-family: 'Montserrat Alternates', sans-serif;
}

.navbarChange .head1 h1 span {
  font-size: 43px;
  color: #ffffff;
  font-weight: bolder;
}

.navbarChange .head1 h1 span:hover {
  color: #dc143c;
}

.navbarChange .head1 h1:hover {
  color: #003893;
}

.navbarChange .head2 {
  width: 70%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  padding: 0 7% 0 0;
}

.navbarChange .head2 .links {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
}

.navbarChange .head2 .links a {
  text-decoration: none;
  color: #ffffff;
  margin: 0 2%;
  font-weight: bolder;
}

.navbarChange .head2 .links a:hover {
  color: #dc143c;
}

.A {
  margin: 0;
  top: 0;
  width: 100%;
  height: 85vh;
}

.A .para {
  background-image: url(./img/welcome.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 700px 78px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 96vh;
  position: relative;
  background-attachment: fixed;
}

.A .para .welcome {
  width: 50%;
  height: 100%;
  position: relative;
  font-size: 2rem;
}

.A .para .welcome .text {
  margin-top: 20%;
  position: absolute;
  left: 21%;
  height: auto;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(145, 83, 83, 0.397)), to(#003893b9));
  background-image: linear-gradient(to right, rgba(145, 83, 83, 0.397), #003893b9);
  color: #dc143cef;
  padding: 10% 5%;
  border-right: 1rem solid;
  border-radius: 50%;
}

.B {
  margin-top: 5%;
  background-image: url(https://img.freepik.com/free-vector/realistic-studio-lights-empty-background-design_1017-27233.jpg?size=626&ext=jpg&ga=GA1.2.871503001.1640476800);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  height: 200vh;
  background-attachment: fixed;
  padding: 15vh 0 0 5%;
}

.B .tshirthead {
  text-align: center;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 2rem;
  color: #003893;
}

.B div.gallery {
  margin: 2% 2%;
  border: 1px solid #ccc;
  float: left;
  width: 20%;
}

.B div.gallery:hover {
  border: 1px solid #777;
  -webkit-transform: scaleX(200px);
          transform: scaleX(200px);
}

.B div.gallery img {
  width: 100%;
  height: auto;
}

.B div.desc {
  padding: 15px;
  text-align: center;
}
